.custom-label {
    margin-bottom: 11px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #60605d;
    margin-bottom: 11px;
    margin-top: 15.5px;
    display: block; }

.DateInput {
    width: 110px;
    &_input {
        font-size: 16px;
        line-height: 16px;
        padding: 10px 11px 7px; } }
.DateRangePickerInput__withBorder,
.SingleDatePickerInput {
    border-color: #adadab; }

.CalendarDay__selected,
.CalendarDay__selected_span {
    background: #ebb92d;
    border-color: #e4e7e7!important;
    color: #60605d;

    &:hover {
        background: #9c7300;
        color: white; } }
// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #ebb82d85; }
.DateInput_input__focused {
    border-color: #ebb92d; }

.DayPickerKeyboardShortcuts_buttonReset {
    display: none; }

div.password-orientations {
    margin: 10px 0;
    & > p.orientations-header {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 5px;
        color: #60605d; }
    & > p.orientation-valid {
        font-weight: 600;
        color: #ebb92d; } }

table.default {
    width: 100%;

    .actions {
        width: 100px; }
    th,td {
        text-align: left;
        padding: 5px 0px; }
    thead tr th {
        border-bottom: 1px solid #eee; }
    button {
        float: right; } }

input.default {
    padding: 10px;
    height: 40px;
    border: solid 1px #adadab;
    background-color: #ffffff;
    text-transform: uppercase;
    &[disabled] {
        background-color: #bbbbbb;
        cursor: not-allowed; }

    &::placeholder {
        color: #666360;
        text-transform: capitalize; }
    &.error {
        border-color: red; } }

.upload-btn-wrapper {
    button.uploadBtn {
        text-align: center;
        border: solid 1px #fcb813;
        color: #fcb813;
        background-color: #ffffff;
        padding: 10px;
        cursor: pointer;

        width: 170.3px;
        height: 35px;

        text-transform: uppercase;

        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            display: block;
            margin-right: 10.5px; } }

    input {
        width: 170.3px;
        height: 35px;
        position: absolute;
        left: calc(100% - 170.3px);
        top: 0;
        opacity: 0;
        cursor: pointer; } }
.float-right {
    float: right; }
.d-inline-block {
    display: inline-block; }
.mt-2 {
    margin-top: 8px!important; }
.text-right {
    text-align: right; }
.clear {
    clear: both; }
