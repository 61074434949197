button{
  cursor: default !important;
}

abbr{
  display: none;
}

.eventosContainer{
  position: absolute;
  background: #fff; 
  width: 210px;
  min-height:15px;
  padding: 20px 15px 15px 15px;
}

.eventosContainer p{
  color: #272723;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.diaUtilTexto{
  color: #fff;
  /*margin-top: 2.6px;*/
  margin-top: 0.6px
}

.diaHoje{
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  border: 1px solid #adadab;
  border-radius: 50%;
}


.eventoFuturo{
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  border: 1px solid #EBB92D;
  border-radius: 50%;
  background-color: #EBB92D;
}

.eventoFuturo:hover,
.eventoFuturo:focus {
  background: #272723;
  border: 1px solid #272723;
}

.eventoFuturo p:hover,
.eventoFuturo p:focus {
  color: #EBB92D;
  font-weight: 300;
  margin-top: 0.5px;
}

.eventoAnterior{
    height: 26px;
    width: 26px;
    display: flex;
    justify-content: center;
    border: 1px solid;
    border-radius: 50%;
    background-color: #adadab;
}

.eventoAnteriorTexto{
  font-weight: 500;
  color: #60605DCC;
  width: 100%;
  height: 100%;
  text-align: center;
  /*margin-top: 2.6px;*/
  margin-top: 0.6px;
}

.react-calendar__navigation__label{
  flex-grow: 1;
  color: #EBB92D;
  font-weight: 600;
  font-family: 'Montserrat';
}

.react-calendar__navigation__arrow{
  font-size: 31px;
  color: #EBB92D;
  display: flex;
  justify-content: center;
}


.react-calendar {
    width: 360px;
    max-width: 100%;
    /*background: white;*/
    background-color: rgba(96,96,93,0.8);
    /*border: 1px solid #a0a096;*/
    font-family: Arial, Helvetica, sans-serif;
    line-height: 0px;
    padding: 10px;
    height: 273px;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }

  /*
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  */
  .react-calendar__navigation {
    display: flex;
    height: 42px;
    margin-bottom: 6px;
    /*margin-bottom: 1em;*/
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    cursor: pointer !important;
  }
  /*
  .react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  */
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    height: 10px;
    padding: 0.5em;
    margin-bottom: 15px;
    font-family: 'Montserrat';
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
  }
  /*
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  */
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #fff;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    /*padding: 10px 6.6667px;*/
    background: none;
    text-align: center;
    line-height: 16px;
  }
  /*
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  */

  /*
  .react-calendar__tile--now {
    *background: #ffff76;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }


  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  */
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  
  button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
  button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button{
    display: none;
  }

  