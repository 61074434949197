.corretor-select.withDnD {
  margin: 10px 0 5px;
  padding: 0 5px 5px;
  border: 1px dashed gray;

  & > div {
    width: calc(100% - 150px); }

  & > .rearrange {
    width: 38px;
    height: 38px;
    display: inline-block;
    background-color: #fff;
    cursor: move;
    margin-top: auto;
    border: 1px solid #adadab;
    margin-left: 12px;
    color: #adadab; } }

