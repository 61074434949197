.ReactModal__Body--open {
    overflow: hidden; }

.modal-presenca {
    .ReactModal__Overlay {
        display: flex;
        align-items: center;
        justify-content: center; }

    .ReactModal__Content {
        max-width: 670px;
        width: 40%;
        max-height: 80vh;
        inset: unset!important;
        overflow: hidden !important;
        padding: 0px !important; } }



